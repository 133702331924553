<template>
  <div>
    <div class="title-tabs">
      <b-row>
        <b-col md="6" class="text-left">General Information</b-col>
        <b-col md="6" class="text-right">
          <span v-if="id != 0">
            <!-- Latest Update : {{ new Date() | moment($formatDate) }} ({{
              new Date() | moment($formatTime)
            }}) -->
          </span>
        </b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <div class="text-black">
          <b-row>
            <b-col sm="12">
              <InputText
                textFloat="Name"
                v-model="form.Name"
                isRequired
                placeholder="Name"
                type="text"
                :isValidate="v.Name.$error"
                :v="v.Name"
              />
            </b-col>
            <b-col sm="6">
              <label class="header-title font-weight-bold"
                >Number Of Privileges <span class="text-error">*</span></label
              >
              <div>
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-total-redeem"
                    v-model="form.totalRedeemStatus"
                    :options="optionsTotalRedeem"
                    name="radio-total-redeem"
                    @change="changeTotalRedeem"
                  ></b-form-radio-group>
                </b-form-group>
                <div class="d-inline-block input-total-box">
                  <InputText
                    textFloat=""
                    v-model="form.Total_Redeemable"
                    class="ml-1 input-total d-inline-block"
                    placeholder="0"
                    type="number"
                    :disabled="!form.totalRedeemStatus"
                    :isValidate="v.Total_Redeemable.$error"
                    :v="v.Total_Redeemable"
                  />
                  <span class="mt-2 ml-3 position-absolute">Privilege</span>
                </div>
              </div>
            </b-col>
            <b-col sm="6">
              <InputText
                textFloat="Limit Redeem Per Customer"
                v-model="form.user_limit"
                class="input-total d-inline-block"
                placeholder="0"
                type="number"
                inputClass="mt-2"
              />
              <span class="mx-2"> in</span>
              <InputText
                textFloat=""
                v-model="form.total_Redeem_Day"
                class="input-total d-inline-block"
                placeholder="0"
                type="number"
                inputClass="mt-2"
              />
              Days
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <label class="header-title font-weight-bold mt-2"
                >Conditions For Receiving The Goods
                <span class="text-error">*</span></label
              >
              <div>
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-pickup-status"
                    v-model="form.pick_Up_Type_Id"
                    value-field="value"
                    :disabled="is_redeem"
                    text-field="text"
                    name="radio-pickup-status"
                  >
                    <b-form-radio value="1">Pick Up at the Branch</b-form-radio>
                    <b-form-radio value="2">Pick Up at Home </b-form-radio>
                    <b-form-radio value="3" :disabled="this.form.Skip == 0"
                      >Nothing
                    </b-form-radio></b-form-radio-group
                  >
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="6">
              <label class="header-title font-weight-bold"
                >Terms and Conditions <span class="text-error">*</span></label
              >
              <div class="mt-2">
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-redeem-status"
                    v-model="form.Skip"
                    :disabled="is_redeem"
                    :options="optionsRedeemReceive"
                    @change="handleRedeemReceiveChange($event)"
                    name="radio-redeem-status"
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Show on Customer Page"
                v-slot="{ ariaDescribedby }"
                class="checkbox-customer"
              >
                <b-form-checkbox
                  v-model="form.is_show_customer"
                  :aria-describedby="ariaDescribedby"
                  :value="1"
                  :unchecked-value="0"
                  name="show_customer_page"
                  >Show on Customer Page
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <InputText
                textFloat="Sort order"
                v-model="form.sort_order"
                class="input-total d-inline-block"
                placeholder="0"
                type="number"
                inputClass="mt-2"
              />
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Allow Customer To Redeem"
                v-slot="{ ariaDescribedby }"
                class="checkbox-customer"
              >
                <b-form-checkbox
                  v-model="form.allow_customer_to_redeem"
                  :aria-describedby="ariaDescribedby"
                  :value="1"
                  :unchecked-value="0"
                  name="allow_custom_redeem"
                  >Allow Customer To Redeem
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Member Tier" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="form.member_id_list"
                  :options="memberList"
                  value-field="id"
                  text-field="name"
                  :aria-describedby="ariaDescribedby"
                  name="member-list-1"
                ></b-form-checkbox-group>
              </b-form-group>

              <div v-if="memberError" class="my-3">
                <span class="text-error"
                  >Please select member tier level, at least 1
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <div class="title-tabs mt-3">
      <b-row>
        <b-col md="6" class="text-left">Conditions</b-col>
        <b-col md="6" class="text-right">
          <span v-if="id != 0">
            Latest Update : {{ new Date() | moment($formatDate) }} ({{
              new Date() | moment($formatTime)
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3 pb-3">
        <div class="text-black">
          <div>
            <p>
              <strong class="text-header">Product And Coupon List</strong>
            </p>
            <b-button
              class="btn-select-branch w-100 w-sm-unset text-nowrap"
              @click.prevent="openProductModal"
            >
              Select Item
              <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
            </b-button>
            <span class="ml-2"
              >{{ productListSelect.length }} Items Selected</span
            >
            <b-row>
              <b-col sm="12" class="mt-3">
                <b-table
                  :fields="fields"
                  :items="productListSelect"
                  :busy="isBusy"
                  show-empty
                  :current-page="filter.page"
                  :per-page="filter.length"
                  empty-text="No matching records found"
                  responsive
                  striped
                  hover
                >
                  <template v-slot:cell(imageUrl)="data">
                    <div class="position-relative picture-text pic-table">
                      <div
                        v-if="data.item.imageUrl"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + data.item.imageUrl + ')',
                        }"
                      ></div>
                      <div
                        v-else-if="data.item.image_url"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image':
                            'url(' + data.item.image_url + ')',
                        }"
                      ></div>
                      <div v-else>
                        <div
                          class="square-box b-contain image"
                          v-bind:style="{
                            'background-image': 'url(' + default_image + ')',
                          }"
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div class="text-left">
                      <p class="m-0" v-if="data.item.name">
                        <b>
                          {{ data.item.name }}
                        </b>
                      </p>
                      <p
                        class="m-0"
                        v-if="data.item.short_description"
                        v-html="data.item.short_description"
                      ></p>
                      <p v-else class="m-0">-</p>
                    </div>
                  </template>
                  <template v-slot:cell(barcode)="data">
                    <p class="m-0" v-if="data.item.barcode">
                      {{ data.item.barcode }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(coupon)="data">
                    <p class="m-0" v-if="data.item.coupon">
                      {{ data.item.coupon }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(validFrom)="data">
                    <p
                      class="m-0 price-text"
                      v-if="data.item.startDate || data.item.validFrom"
                    >
                      {{
                        $moment(
                          data.item.startDate || data.item.validFrom
                        ).format("DD/MM/YYYY")
                      }}
                      <br />
                      {{
                        $moment(
                          data.item.startDate || data.item.validFrom
                        ).format("HH:mm:ss")
                      }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(validTo)="data">
                    <p
                      class="m-0 price-text"
                      v-if="data.item.endDate || data.item.validTo"
                    >
                      {{
                        $moment(data.item.endDate || data.item.validTo).format(
                          "DD/MM/YYYY"
                        )
                      }}
                      <br />
                      {{
                        $moment(data.item.endDate || data.item.validTo).format(
                          "HH:mm:ss"
                        )
                      }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(price)="data">
                    <p class="m-0 price-text" v-if="data.item.price">
                      {{ data.item.price | numeral("0,0.00") }}
                    </p>
                    <p v-else class="m-0 price-text">-</p>
                  </template>
                  <template v-slot:cell(redeem_type_name)="data">
                    <p
                      class="m-0 price-text"
                      v-if="data.item.allow_customer_redeem"
                    >
                      {{
                        data.item.allow_customer_redeem == "1"
                          ? "Redeem By Customer Coupon"
                          : "Redeem By Booth Coupon"
                      }}
                    </p>
                    <p v-else class="m-0 price-text">
                      {{ data.item.redeem_type_name }}
                    </p>
                  </template>
                  <template v-slot:cell(point)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.point_used"
                      placeholder="0"
                      type="number"
                      class="mb-0"
                      @input="handlePoint(data.item)"
                    />
                  </template>
                  <template v-slot:cell(is_upcoming_reward)="data">
                    <b-form-checkbox
                      value="1"
                      unchecked-value="0"
                      v-model="data.item.is_upcoming_reward"
                      size="lg"
                      @input="handleIsUpcomingReward(data.item)"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(id)="data">
                    <b-form-checkbox
                      value="1"
                      unchecked-value="0"
                      v-model="data.item.is_theme"
                      size="lg"
                      @input="handleTheme(data.item)"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(active)="data">
                    <!-- <div v-show="false">{{ data.item.active }}</div> -->
                    <b-form-checkbox
                      switch
                      v-model="data.item.active"
                      :name="'' + data.item.id"
                      @input="handleActive(data.item)"
                      class="radio-active"
                      size="sm"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(sort_order)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.sort_order"
                      placeholder="0"
                      type="number"
                      class="mb-0 input-width"
                      @input="handleSortOrder(data.item)"
                      @blur="(val) => sortDefault(data.item)"
                    />
                  </template>
                  <template v-slot:cell(delete)="data">
                    <b-button
                      variant="link"
                      class="pb-3"
                      v-if="form.redeemType != 1"
                    >
                      <router-link
                        :to="'/product/detail/' + data.item.id"
                        target="_blank"
                      >
                        <u>Detail</u>
                      </router-link>
                    </b-button>

                    <b-button
                      variant="icon"
                      class="px-0"
                      :disabled="data.item.is_redeem == 1"
                      @click="deleteProduct(data.item)"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="form-inline justify-content-start">
                <p class="mb-0 p-gray">
                  Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
                  {{
                    filter.start + filter.length >= rows
                      ? rows
                      : filter.start + filter.length
                  }}
                  of {{ rows }} entries
                </p>
              </b-col>
              <b-col cols="12" md="6" class="form-inline justify-content-end">
                <div>
                  <b-pagination
                    v-model="filter.page"
                    :total-rows="rows"
                    :per-page="filter.length"
                    @change="pagination"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    class="m-md-0"
                  ></b-pagination>
                </div>
                <div class="ml-2">
                  <b-form-select
                    v-model="filter.length"
                    @change="handlePerpage"
                    :options="pageOptions"
                  ></b-form-select>
                </div>
              </b-col>

              <b-col sm="12" v-if="v.items.selectProduct.$error">
                <p
                  class="text-error"
                  v-if="form.items.selectProduct.length == 0"
                >
                  Please select at least 1 product.
                </p>
                <p class="text-error" v-else>
                  Please enter points more than 0 on products.
                </p>
              </b-col>
            </b-row>
            <div v-if="pleaseSelectProduct"></div>
          </div>
          <hr />
          <div class="mt-3">
            <b-row>
              <b-col sm="6">
                <div>
                  <label class="font-weight-bold">
                    Date of Use (Start) <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      type="datetime"
                      :input-style="styleDatetime"
                      v-model="form.Valid_From"
                      placeholder="DD/MM/YYYY (HH:MM)"
                      format="dd/MM/yyyy (HH:mm)"
                      value-zone="Asia/Bangkok"
                      @close="changeStartDate"
                      ref="transferDateStart"
                      :isValidate="v.Valid_From.$error"
                      :v="v.Valid_From"
                    >
                    </datetime>
                    <div
                      :class="
                        'icon-primary text-right ' + id == 0
                          ? ''
                          : 'cursor-default'
                      "
                      @click="
                        $refs.transferDateStart.isOpen = id == 0 ? true : false
                      "
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="v.Valid_From.$error">
                  <span class="text-error" v-if="v.Valid_From.required == false"
                    >Please select date.</span
                  >
                </div>
              </b-col>
              <b-col sm="6">
                <div>
                  <label class="font-weight-bold">
                    Date of Use (End) <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      type="datetime"
                      :input-style="styleDatetime"
                      v-model="form.Valid_To"
                      placeholder="DD/MM/YYYY (HH:MM)"
                      format="dd/MM/yyyy (HH:mm)"
                      value-zone="Asia/Bangkok"
                      ref="transferDateEnd"
                      :min-datetime="form.Valid_From"
                      :isValidate="v.Valid_To.$error"
                      :v="v.Valid_To"
                    >
                    </datetime>
                    <div
                      class="icon-primary text-right"
                      @click="$refs.transferDateEnd.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="v.Valid_To.$error">
                  <span class="text-error" v-if="v.Valid_To.required == false"
                    >Please select date.</span
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
    <div class="no-gutters bg-white mt-3 py-2 px-3">
      <b-form-checkbox
        switch
        v-model="form.Status"
        class="radio-active"
        size="lg"
        :value="1"
        :unchecked-value="0"
      >
        <span class="ml-2 main-label">{{
          form.Status ? "Active" : "Inactive"
        }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { BIcon } from "bootstrap-vue";
export default {
  name: "RedeemFormDetail",
  components: {
    InputText,
    BIcon,
  },
  props: {
    id: {
      type: Number,
      isRequired: true,
    },
    form: {
      type: Object,
      isRequired: true,
    },
    v: {
      type: Object,
      isRequired: true,
    },
    is_redeem: {
      type: Boolean,
      isRequired: true,
    },
    memberList: { type: Object | Array, required: false },
    memberError: { type: Boolean, required: false },
  },
  data() {
    return {
      isBusy: false,
      pleaseSelectProduct: false,
      filter: {
        redeemId: parseInt(this.id),
        branchId: 0,
        redeemOrderStatus: 0,
        search: "",
        onlyInRedeem: 1,
        page: 1,
        length: 5,
        start: 0,
        selectProductList: [],
        DeleteProductList: [],
      },
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      fields: [
        {
          key: "imageUrl",
          label: "Image",
          class: "w-100px",
          type: 1,
        },
        {
          key: "name",
          label: "Name / Detail",
          class: "w-200 text-nowrap",
          type: 1,
        },
        {
          key: "redeem_type_name",
          label: "Type",
          class: "w-150 text-nowrap",
          type: 1,
        },

        {
          key: "point",
          label: "Point",
          class: "w-50px text-nowrap",
          type: 2,
        },
        {
          key: "is_upcoming_reward",
          label: "Upcoming Reward",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "id",
          label: "Theme Setting",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "active",
          label: "Active",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "sort_order",
          label: "Sort Order",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "delete",
          label: "",
          class: "w-50px text-nowrap",
          type: 1,
        },
      ],
      productListSelect: [],
      optionsTotalRedeem: [
        { text: "Unlimited Number", value: false },
        { text: "Limited Number", value: true },
      ],
      optionsRedeemReceive: [
        { text: "Get It Now", value: 1 },
        { text: "Prepare", value: 0 },
      ],
      optionsRedeemType: [
        { text: "Single Product", value: 1 },
        // { text: "Privillege", value: 2 },
        { text: "Coupon", value: 3 },
      ],
      optionRedeemPickup: [
        { text: "Pick Up at the Branch", value: 1 },
        { text: "Pick Up at Home", value: 2 },
        { text: "Nothing", value: 3, disable: true },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  computed: {
    widthManage() {
      return this.form.redeemType == 1 ? "5%" : "10%";
    },
  },
  created: async function () {},
  methods: {
    handleRedeemReceiveChange(val) {
      if (this.form.pick_Up_Type_Id == 3 && val == 0) {
        this.form.pick_Up_Type_Id = 1;
      }
    },
    handleTheme(items) {
      let index = this.filter.selectProductList.findIndex(
        (el) => el.id == items.id
      );
      if (index == -1) {
        this.filter.selectProductList.push(items);
      } else {
        this.filter.selectProductList[0].is_theme = items.is_theme;
      }
    },
    handleActive(items) {
      let index = this.filter.selectProductList.findIndex(
        (el) => el.id == items.id
      );
      if (index == -1) {
        this.filter.selectProductList.push({ ...items });
      } else {
        this.filter.selectProductList[0].active = items.active;
      }
    },
    handleSortOrder(items) {
      let index = this.filter.selectProductList.findIndex(
        (el) => el.id == items.id
      );
      if (index == -1) {
        this.filter.selectProductList.push(items);
      } else {
        this.filter.selectProductList[0].sort_order = items.sort_order;
      }
    },
    handlePoint(items) {
      let index = this.filter.selectProductList.findIndex(
        (el) => el.id == items.id
      );
      if (index == -1) {
        this.filter.selectProductList.push(items);
      } else {
        this.filter.selectProductList[index].point_used = items.point_used;
      }
    },
    handleIsUpcomingReward(items) {
      let index = this.filter.selectProductList.findIndex(
        (el) => el.id == items.id
      );
      if (index == -1) {
        this.filter.selectProductList.push(items);
      } else {
        this.filter.selectProductList[index].is_upcoming_reward =
          items.is_upcoming_reward;
      }
    },
    changeTotalRedeem() {
      if (!this.form.totalRedeemStatus) {
        this.form.Total_Redeemable = 0;
      }
    },
    openProductModal() {
      this.$emit("openProductModal", this.form.redeemType);
    },
    async pagination(page) {
      this.filter.page = page;
      await this.setProductPoint();
      this.getProductSelectList(1);
    },
    async handlePerpage(value) {
      this.filter.page = 1;
      this.filter.length = value;
      await this.setProductPoint();
      this.getProductSelectList(1);
    },
    changeStartDate() {
      if (this.form.Valid_From && this.form.Valid_To) {
        this.form.Valid_To =
          this.form.Valid_From >= this.form.Valid_To ? "" : this.form.Valid_To;
      }
    },
    changeRedeemType() {
      this.form.items.selectProduct = [];
      this.filter.selectProductList = [];
      this.productListSelect = [];
      this.pagination(1);
    },
    async getProductSelectList(flag) {
      this.isBusy = true;

      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.redeemType = this.form.redeemType;

      this.filter.DeleteProductList.map(
        (el) => (el.id = el.redeem_target_id || el.id)
      );

      this.filter.selectProductList.map(
        (el) => (el.point_used = el.point_used ? el.point_used : 0)
      );

      await this.$store.dispatch("getRedeemDetail", this.filter);

      const data = await this.$store.state.redeem.redeemDetail;

      const removeDupValue = data.detail.data.redeemItemList.filter(
        (value, index, self) => {
          if (index === self.findIndex((t) => t.name === value.name)) {
            return value;
          }
        }
      );

      if (data.result == 1) {
        if (this.filter.selectProductList.length) {
          var index = 0;
          for (var point of removeDupValue) {
            let findValue = data.detail.data.redeemItemList.find(
              (el) => el.name == point.name && el.redeem_target_id == 0
            );
            let find = this.filter.selectProductList.find(
              (el) => el.id == point.id
            );

            if (findValue && point.redeem_target_id != 0) {
              removeDupValue.splice(index, 1);
              removeDupValue.push(findValue);
            }

            point.point_used = find ? find.point_used : point.point_used;
            point.active = find ? find.active : point.active || 1;
            point.sort_order = find ? find.sort_order : point.sort_order;
            index++;
          }
        }
        this.productListSelect = removeDupValue;
        await this.productListSelect.forEach((e, index) => {
          e.index = index;
        });
        this.rows = removeDupValue.length;

        this.form.items.selectProduct = this.productListSelect;

        // await this.setProductPoint();
      } else {
        this.productListSelect = [];
        this.rows = 0;
      }

      this.isBusy = false;
    },
    async deleteProduct(data) {
      this.filter.DeleteProductList.push(data);

      this.form.items.selectProduct.splice(
        this.form.items.selectProduct.findIndex((el) => el.id == data.id),
        1
      );

      this.productListSelect = this.productListSelect.filter((el) =>
        this.form.items.selectProduct.find((els) => el.id == els.id)
      );
    },
    setProductPoint() {
      this.productListSelect.forEach((e) => {
        this.filter.selectProductList.forEach((select) => {
          if (e.id == select.id) {
            select.point_used = parseInt(e.point_used);
            select.is_theme = parseInt(e.is_theme);
            select.is_upcoming_reward = parseInt(e.is_upcoming_reward);
            select.active = parseInt(e.active);
            select.sort_order = parseInt(e.sort_order);
            e.point_used = parseInt(select.point_used);
            e.is_theme = parseInt(select.is_theme);
            e.is_upcoming_reward = parseInt(select.is_upcoming_reward);
            e.active = parseInt(select.active);
            e.sort_order = parseInt(select.sort_order);
          }
        });
      });

      this.form.items.selectProduct = this.filter.selectProductList;
    },
    sortDefault(items) {
      if (!items.sort_order) items.sort_order = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 50%;
  height: 50%;
  max-width: 150px;
  min-width: 50px;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
label {
  font-size: 16px;
}
.text-header {
  color: #000;
  font-size: 16px;
}

.input-total {
  width: 80px;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.status-box {
  width: 90px;
}
.status-box label {
  font-size: 18px;
}
.status-switch {
  position: absolute;
  margin-top: -18px;
}

::v-deep .vdatetime-popup {
  width: 270px;
}
::v-deep img {
  width: 230px !important;
  height: 230px !important;
}
table {
  b {
    color: black;
  }
}
::v-deep .checkbox-customer legend {
  margin-bottom: 0.5rem;
}
.input-width {
  max-width: 80px;
  margin: auto;
}
</style>
